@import url('https://fonts.googleapis.com/css2?family=SUSE:wght@100..800&display=swap');

html {
    --section-background-color: linear-gradient(
            to bottom left,
            rgba(17, 16, 16, 0.15),
            rgba(12, 8, 24, 0.35)
    );

    --image-gradient: linear-gradient(
            to bottom left,
            rgba(17, 16, 16, 0.678),
            rgba(12, 10, 22, 0.863)
    );

    --imp-text-color: #4169e1;
}

.blue {
    color: var(--imp-text-color) !important;
}

.light-blue {
    color: #00a6ff;
}

button:focus {
    box-shadow: none !important;
}

/* --------- */
/*  Preloader */
/* --------- */
#preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: #0c0513;
    background-image: url(./Assets/pre.svg);
    background-repeat: no-repeat;
    background-position: center;
}

#preloader-none {
    opacity: 0;
}

#no-scroll {
    overflow: hidden;
    height: 100vh;
}

/* --------- */
/*Scrollbar   */
/* --------- */

::-webkit-scrollbar {
    width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #2d1950;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(135, 206, 250, 0.96);
    border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgba(162, 218, 255, 0.911);
    border-radius: 12px;
}

/* --------- */
/* Navbar Section  */
/* --------- */
.sticky {
    background-color: #1b1a2ea9 !important;
    transition: all 0.3s ease-out 0s !important;
    box-shadow: 0 10px 10px 0 rgba(9, 5, 29, 0.171) !important;
    backdrop-filter: blur(15px) !important;
}

.navbar {
    position: fixed !important;
    transition: all 0.3s ease-out 0s !important;
    padding: 0.3rem 2rem !important;
    font-size: 1.2rem !important;
}

.navbar-toggler {
    position: relative !important;
    background-color: transparent !important;
    border-color: transparent !important;
}

.navbar-toggler span {
    display: block !important;
    background-color: #3b68f3 !important;
    height: 4px !important;
    width: 27px !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    transform: rotate(0deg) !important;
    left: 0 !important;
    opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
    outline: 0 !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
    transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
    position: absolute !important;
    left: 12px !important;
    top: 10px !important;
    transform: rotate(135deg) !important;
    opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
    height: 12px !important;
    visibility: hidden !important;
    background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
    position: absolute !important;
    left: 12px !important;
    top: 10px !important;
    transform: rotate(-135deg) !important;
    opacity: 0.9 !important;
}

@media (max-width: 767px) {
    .navbar {
        padding: 1rem 2rem !important;
        font-size: 1.4rem !important;
        background-color: #181a27 !important;
    }

    .navbar-nav .nav-item a::after {
        display: none !important;
    }
}

.navbar-brand {
    color: rgb(250, 250, 250) !important;
}

.logo {
    height: 1.6em !important;
    width: 3.0em !important;
}

.navbar-nav .nav-link {
    color: white !important;
    padding-right: 1rem !important;
    padding-left: 1rem !important;
}

.nav-link {
    padding: 0.8rem 1rem !important;
}

@media (max-width: 767px) {
    .nav-link {
        padding: 0.7rem 1rem !important;
    }
}

.navbar-nav .nav-item {
    position: relative;
    margin-left: 20px;
}

.navbar-nav .nav-item a {
    font-weight: 400;
    transition: all 0.3s ease-out 0s;
    position: relative;
    z-index: 1;
}

.navbar-nav .nav-item a::after {
    content: "";
    position: relative;
    display: block;
    height: 5px;
    width: 0;
    border-radius: 16px;
    background: #2856de;
    bottom: 1px;
    left: 0;
    z-index: -1;
    transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
    width: 100%;
}

/* --------- */
/* Home section */
/* --------- */
.rocket {
    animation-name: rocket-launch;
    animation-duration: 7s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-delay: .5s;
    display: inline-block;
    transform-box: fill-box;
    height: 1em;
    position: relative;
    z-index: 99 !important;
    overflow: hidden;
}

@keyframes rocket-launch {
    0.000% {
        transform: rotate(0.007deg);
    }
    14.286% {
        transform: rotate(0.007deg);
    }
    15.300% {
        transform: rotate(11.645deg);
    }
    17.058% {
        transform: rotate(30.446deg);
    }
    18.738% {
        transform: rotate(46.339deg);
    }
    20.339% {
        transform: rotate(59.358deg);
    }
    21.868% {
        transform: rotate(69.715deg);
    }
    23.335% {
        transform: rotate(77.680deg);
    }
    24.754% {
        transform: rotate(83.509deg);
    }
    26.136% {
        transform: rotate(87.409deg);
    }
    27.495% {
        transform: rotate(89.528deg);
    }
    28.845% {
        transform: rotate(89.948deg);
    }
    30.197% {
        transform: rotate(88.684deg);
    }
    31.566% {
        transform: rotate(85.689deg);
    }
    32.965% {
        transform: rotate(80.847deg);
    }
    34.406% {
        transform: rotate(73.980deg);
    }
    35.903% {
        transform: rotate(64.853deg);
    }
    37.467% {
        transform: rotate(53.197deg);
    }
    39.107% {
        transform: rotate(38.760deg);
    }
    40.827% {
        transform: rotate(21.400deg);
    }
    42.621% {
        transform: rotate(1.231deg);
    }
    44.475% {
        transform: rotate(-21.231deg);
    }
    46.363% {
        transform: rotate(-45.000deg);
    }
    48.250% {
        transform: rotate(-68.769deg);
    }
    50.104% {
        transform: rotate(-91.231deg);
    }
    51.898% {
        transform: rotate(-111.400deg);
    }
    53.618% {
        transform: rotate(-128.760deg);
    }
    55.258% {
        transform: rotate(-143.197deg);
    }
    56.822% {
        transform: rotate(-154.853deg);
    }
    58.319% {
        transform: rotate(-163.980deg);
    }
    59.760% {
        transform: rotate(-170.847deg);
    }
    61.159% {
        transform: rotate(-175.689deg);
    }
    62.528% {
        transform: rotate(-178.684deg);
    }
    63.881% {
        transform: rotate(-179.948deg);
    }
    65.230% {
        transform: rotate(-179.528deg);
    }
    66.589% {
        transform: rotate(-177.409deg);
    }
    67.971% {
        transform: rotate(-173.509deg);
    }
    69.390% {
        transform: rotate(-167.680deg);
    }
    70.857% {
        transform: rotate(-159.715deg);
    }
    72.386% {
        transform: rotate(-149.358deg);
    }
    73.988% {
        transform: rotate(-136.339deg);
    }
    75.667% {
        transform: rotate(-120.446deg);
    }
    77.425% {
        transform: rotate(-101.645deg);
    }
    79.252% {
        transform: rotate(-80.235deg);
    }
    81.127% {
        transform: rotate(-56.971deg);
    }
    83.019% {
        transform: rotate(-33.029deg);
    }
    84.893% {
        transform: rotate(-9.765deg);
    }
    85.714% {
        transform: rotate(0.007deg);
    }
    100.000% {
        transform: rotate(0.007deg);
    }
}

.rocket-container {
    animation-name: rocket-container-launch;
    animation-duration: 7s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-delay: .5s;
    display: inline-block;
    transform-box: fill-box;
    position: relative;
    z-index: 99 !important;
}

@keyframes rocket-container-launch {
    0.000% {
        transform: translateX(0.000em) translateY(0.000em);
    }
    14.286% {
        transform: translateX(0.000em) translateY(0.000em);
    }
    15.300% {
        transform: translateX(0.576em) translateY(-0.468em);
    }
    17.058% {
        transform: translateX(1.754em) translateY(-0.988em);
    }
    18.738% {
        transform: translateX(2.976em) translateY(-1.125em);
    }
    20.339% {
        transform: translateX(4.137em) translateY(-0.962em);
    }
    21.868% {
        transform: translateX(5.191em) translateY(-0.584em);
    }
    23.335% {
        transform: translateX(6.131em) translateY(-0.065em);
    }
    24.754% {
        transform: translateX(6.974em) translateY(0.541em);
    }
    26.136% {
        transform: translateX(7.743em) translateY(1.200em);
    }
    27.495% {
        transform: translateX(8.463em) translateY(1.886em);
    }
    28.845% {
        transform: translateX(9.163em) translateY(2.583em);
    }
    30.197% {
        transform: translateX(9.870em) translateY(3.277em);
    }
    31.566% {
        transform: translateX(10.611em) translateY(3.952em);
    }
    32.965% {
        transform: translateX(11.413em) translateY(4.588em);
    }
    34.406% {
        transform: translateX(12.303em) translateY(5.156em);
    }
    35.903% {
        transform: translateX(13.299em) translateY(5.612em);
    }
    37.467% {
        transform: translateX(14.409em) translateY(5.893em);
    }
    39.107% {
        transform: translateX(15.610em) translateY(5.918em);
    }
    40.827% {
        transform: translateX(16.827em) translateY(5.597em);
    }
    42.621% {
        transform: translateX(17.922em) translateY(4.871em);
    }
    44.475% {
        transform: translateX(18.703em) translateY(3.761em);
    }
    46.363% {
        transform: translateX(18.989em) translateY(2.409em);
    }
    48.250% {
        transform: translateX(18.703em) translateY(1.057em);
    }
    50.104% {
        transform: translateX(17.922em) translateY(-0.054em);
    }
    51.898% {
        transform: translateX(16.827em) translateY(-0.779em);
    }
    53.618% {
        transform: translateX(15.610em) translateY(-1.100em);
    }
    55.258% {
        transform: translateX(14.409em) translateY(-1.075em);
    }
    56.822% {
        transform: translateX(13.299em) translateY(-0.795em);
    }
    58.319% {
        transform: translateX(12.303em) translateY(-0.339em);
    }
    59.760% {
        transform: translateX(11.413em) translateY(0.229em);
    }
    61.159% {
        transform: translateX(10.611em) translateY(0.866em);
    }
    62.528% {
        transform: translateX(9.870em) translateY(1.541em);
    }
    63.881% {
        transform: translateX(9.163em) translateY(2.234em);
    }
    65.230% {
        transform: translateX(8.463em) translateY(2.932em);
    }
    66.589% {
        transform: translateX(7.743em) translateY(3.618em);
    }
    67.971% {
        transform: translateX(6.974em) translateY(4.277em);
    }
    69.390% {
        transform: translateX(6.131em) translateY(4.883em);
    }
    70.857% {
        transform: translateX(5.191em) translateY(5.402em);
    }
    72.386% {
        transform: translateX(4.137em) translateY(5.779em);
    }
    73.988% {
        transform: translateX(2.976em) translateY(5.943em);
    }
    75.667% {
        transform: translateX(1.754em) translateY(5.805em);
    }
    77.425% {
        transform: translateX(0.576em) translateY(5.286em);
    }
    79.252% {
        transform: translateX(-0.388em) translateY(4.358em);
    }
    81.127% {
        transform: translateX(-0.939em) translateY(3.101em);
    }
    83.019% {
        transform: translateX(-0.939em) translateY(1.716em);
    }
    84.893% {
        transform: translateX(-0.388em) translateY(0.459em);
    }
    85.714% {
        transform: translateX(0.000em) translateY(0.000em);
    }
    100.000% {
        transform: translateX(0.000em) translateY(0.000em);
    }
}

.rocket-launcher {
    animation-name: rocket-launcher-vibrate;
    animation-duration: 7s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-delay: .5s;
    display: inline-block;
    transform-box: fill-box;
    position: relative;
    z-index: 99 !important;
}

@keyframes rocket-launcher-vibrate {
    0.000% {
        transform: translate(0.000px, 0.000px);
    }
    0.714% {
        transform: translate(-2.000px, -2.000px);
    }
    1.429% {
        transform: translate(2.000px, -2.000px);
    }
    2.143% {
        transform: translate(-2.000px, 2.000px);
    }
    2.857% {
        transform: translate(2.000px, 2.000px);
    }
    3.571% {
        transform: translate(-2.000px, -2.000px);
    }
    4.286% {
        transform: translate(2.000px, -2.000px);
    }
    5.000% {
        transform: translate(-2.000px, 2.000px);
    }
    5.714% {
        transform: translate(-2.000px, -2.000px);
    }
    6.429% {
        transform: translate(2.000px, -2.000px);
    }
    7.143% {
        transform: translate(0.000px, 0.000px);
    }
    7.857% {
        transform: translate(-2.000px, -2.000px);
    }
    8.571% {
        transform: translate(2.000px, -2.000px);
    }
    9.286% {
        transform: translate(-2.000px, 2.000px);
    }
    10.000% {
        transform: translate(2.000px, 2.000px);
    }
    10.714% {
        transform: translate(-2.000px, -2.000px);
    }
    11.429% {
        transform: translate(2.000px, -2.000px);
    }
    12.143% {
        transform: translate(-2.000px, 2.000px);
    }
    12.857% {
        transform: translate(-2.000px, -2.000px);
    }
    13.571% {
        transform: translate(2.000px, -2.000px);
    }
    14.286% {
        transform: translate(0.000px, 0.000px);
    }
    100.000% {
        transform: translate(0.000px, 0.000px);
    }
}

#tsparticles {
    position: fixed !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.home-header {
    padding-top: 80px !important;
}

.home-section {
    position: relative;
    z-index: -1;
    /*background-image: var(--image-gradient), url(./Assets/ball-7280265.jpg);*/
    background-position: top center;
    background-repeat: no-repeat;
    padding-bottom: 30px !important;
    padding-top: 30px !important;
    overflow: hidden;
}

.home-content {
    padding: 9rem 0 2rem !important;
    color: whitesmoke;
    text-align: left;
}

.home-image {
    z-index: 1 !important;
    position: relative;
}

.heading {
    font-size: 1.0em !important;
    padding-left: 50px !important;
    z-index: 100 !important;
    position: relative;
}

.heading-name {
    font-size: 1.0em !important;
    padding-left: 45px !important;
    padding-top: 15px;
    z-index: 100 !important;
    position: relative;
    display: block;
}

.main-name {
    color: #3f48f1;
    z-index: 100 !important;
    position: relative;
}

.Typewriter__wrapper {
    font-size: 2.2em !important;
    color: #5c7fe8 !important;
    font-weight: 600 !important;
}

.Typewriter__cursor {
    font-size: 2.4em !important;
    color: #466fec !important;
}

@media (max-width: 767px) {
    .Typewriter__wrapper {
        font-size: 1.4em !important;
        font-weight: 500 !important;
        position: absolute !important;
    }

    .Typewriter__cursor {
        display: none !important;
    }
}

.myAvtar {
    justify-content: center !important;
    padding-top: 9em !important;
}

@media (max-width: 767px) {
    .myAvtar {
        padding-top: 2em !important;
        padding-bottom: 2em !important;
    }
}

.home-about-section {
    position: relative;
    padding-bottom: 70px !important;
    padding-top: 70px !important;
}

.home-about-description {
    color: white !important;
    padding-top: 100px !important;
    padding-bottom: 20px !important;
    text-align: center;
}

.home-about-body {
    padding-top: 50px;
    font-size: 1.2em !important;
    text-align: left;
}

.home-about-social {
    text-align: center !important;
    padding-top: 25px;
    color: white !important;
}

.home-about-social-links {
    justify-content: center !important;
    padding-top: 15px !important;
    display: inline-block !important;
    position: relative !important;
    padding-inline-start: 0 !important;
}

.home-social-icons {
    position: relative !important;
    display: inline-block !important;
    width: 40px !important;
    height: 40px !important;
    text-align: center !important;
    font-size: 1.2em !important;
    line-height: 2em !important;
    background: rgba(255, 255, 255, 0.972) !important;
    border-radius: 50% !important;
    transition: 0.5s !important;
}

.home-social-icons::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #191970;
    transition: 0.5s;
    transform: scale(0.9);
    z-index: -1;
}

.home-social-icons:hover::before {
    transform: scale(1.1);
    box-shadow: 0 0 15px #2626a1;
}

.home-social-icons:hover {
    color: #3131c4;
    box-shadow: 0 0 5px #3131c4;
    text-shadow: 0 0 2px #3131c4;
}

.social-icons {
    display: inline-block !important;
    padding-right: 15px;
    padding-left: 15px;
}

.icon-colour {
    color: #050580 !important;
}

/* --------- */
/* Footer */
/* --------- */
.footer {
    background-color: rgb(10, 4, 22);
    bottom: 0 !important;
    padding-top: 10px !important;
    padding-bottom: 8px !important;
}

.footer-copywright {
    text-align: center !important;
}

.footer-body {
    z-index: 1;
    text-align: center !important;
}

@media (max-width: 767px) {
    .footer-copywright {
        text-align: center !important;
    }

    .footer-body {
        text-align: center !important;
    }
}

.footer h3 {
    font-size: 1em;
    color: white !important;
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
}

.footer-icons {
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
    padding: 0 !important;
}

/* --------- */
/* Projects */
/* --------- */
.project-section {
    position: relative !important;
    padding-top: 150px !important;
    padding-bottom: 30px !important;
    background-image: var(--section-background-color) !important;
}

.project-card {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
    height: auto !important;
}

.project-card-view {
    box-shadow: 0 4px 5px 3px rgba(45, 45, 115, .459) !important;
    color: white !important;
    background-color: transparent !important;
    opacity: 1.0 !important;
    transition: all 0.5s ease 0s !important;
    height: 100% !important;
}

.project-card-view:hover {
    transform: scale(1.02) !important;
    overflow: hidden !important;
    box-shadow: 0 4px 4px 5px rgba(57, 57, 148, 0.46) !important;
}

.blog-card {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
    height: auto !important;
}

.card-img-top {
    padding: 20px !important;
    opacity: 1.0 !important;
    border-radius: 10px !important;
}

.btn-primary {
    color: #fff !important;
    background-color: #2626a1 !important;
    border-color: #2626a1 !important;
}

.btn-primary:hover {
    color: #fff !important;
    background-color: #1818c0 !important;
    border-color: #1818c0 !important;
}

.btn:focus {
    outline: none !important;
    box-shadow: none !important;
}

.project-heading {
    color: white !important;
    font-size: 2.3em !important;
    font-weight: 500 !important;
    padding-top: 10px !important;
}

/* --------- */
/* About */
/* --------- */

.about-section {
    position: relative !important;
    padding-top: 150px !important;
    padding-bottom: 30px !important;
    background-image: var(--section-background-color) !important;
    color: white !important;
}

.tech-icons {
    font-size: 4.5em !important;
    margin: 15px !important;
    padding: 10px !important;
    opacity: 0.93 !important;
    border: 1.7px solid rgba(45, 57, 115, 0.65) !important;
    vertical-align: middle !important;
    text-align: center !important;
    border-radius: 5px !important;
    display: grid !important;
    box-shadow: 4px 5px 4px 3px rgba(0, 19, 110, 0.13) !important;
    overflow: hidden !important;
    transition: all 0.4s ease 0s !important;
    justify-content: center;
    align-items: center;
    container-type: inline-size;
}

@media (max-width: 767px) {
    .tech-icons {
        margin: 10px !important;
    }
}

.tech-icons:hover {
    transform: scale(1.05) !important;
    overflow: hidden !important;
    border: 2.2px solid rgba(115, 151, 230, 0.88) !important;
}

.tech-icon-images {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    /*line-height: 1.6 !important;*/
    height: 1.5em;
    overflow: hidden !important;
    transition: all 0.4s ease 0s !important;
    vertical-align: middle !important;
    text-align: center !important;
    grid-column: 1;
    grid-row: 1;
}

.tech-icon-react-images {
    overflow: hidden !important;
    height: 1.5em;
    transition: all 0.4s ease 0s !important;
    vertical-align: middle !important;
    text-align: center !important;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    grid-column: 1;
    grid-row: 1;
}

.tech-icon-text {
    font-family: "SUSE", sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
    font-size: 0.5em !important;
    vertical-align: middle !important;
    text-align: center !important;
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative;
    top: 0.3em;
    overflow: hidden !important;
    transition: all 0.4s ease 0s !important;
    grid-column: 1;
    grid-row: 1;
}

.tech-icons-hidden {
    visibility: hidden;
    font-size: 0.015em !important;
    text-align: center !important;
    transition: all 0.4s ease 0s !important;
    font-family: "SUSE", sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
    grid-column: 1;
    grid-row: 1;
}

.tech-icons:hover > .tech-icons-hidden {
    visibility: visible;
    transform: translateY(30.0em) scale(12) !important;
}

.tech-icons:hover > .tech-icon-images, .tech-icons:hover > .tech-icon-react-images, .tech-icons:hover > .tech-icon-text {
    transform: translateY(-0.40em) scale(0.65) !important;
}

.tech-icons:hover > .tech-icon-text {
    transform: translateY(-0.80em) scale(0.65) !important;
}

@container (max-width: 150px) {
    .tech-icon-text {
        font-size: 0.31em !important;
    }
    .tech-icons-hidden {
        font-size: 0.010em !important;
    }
}

.quote-card-view {
    border: none !important;
    color: white !important;
    background-color: transparent !important;
}

.about-activity {
    list-style: none !important;
    text-align: left !important;
    padding-left: 1px !important;
}

.about-quote {
    padding-top: 30px !important;
    color: #3e82da;
}

.about-quote-light {
    color: #7a94ce;
}

.blockquote-footer {
    color: #3e82da !important;
}

@media (max-width: 767px) {
    .about-img {
        padding-top: 0 !important;
    }
}

/* --------- */
/* Resume */
/* --------- */

.resume-section {
    position: relative !important;
    padding-top: 110px !important;
    padding-bottom: 30px !important;
    background-image: var(--section-background-color) !important;
    color: white !important;
}

.resume {
    padding-top: 50px;
    padding-bottom: 50px;
    justify-content: center;
}

.resume-left {
    padding-right: 80px !important;
}

.resume-right {
    padding-left: 80px !important;
}

@media (max-width: 767px) {
    .resume-left {
        padding-right: 15px !important;
        padding-left: 15px !important;
    }

    .resume-right {
        padding-right: 15px !important;
        padding-left: 15px !important;
    }
}

.resume .resume-title {
    font-size: 2em;
    font-weight: 700;
    padding-top: 30px;
    padding-bottom: 30px;
}

.resume .resume-item {
    padding: 0 0 10px 25px;
    margin-top: -2px;
    border-left: 2px solid #2856de;
    position: relative;
}

.resume .resume-item .resume-title {
    line-height: 18px;
    font-size: 0.9em;
    background: rgba(52, 63, 121, 0.36);
    padding: 8px 15px;
    display: inline-block;
    font-weight: 600;
    margin-bottom: 10px;
}

.resume .resume-item ul {
    padding-left: 20px;
    text-align: justify;
}

.resume .resume-item ul li {
    padding-bottom: 10px;
    list-style: none;
}

.resume .resume-item:last-child {
    padding-bottom: 0;
}

.resume .resume-item::before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50px;
    left: -9px;
    top: 0;
    background: #fff;
    border: 2px solid #495ca8;
}

.like-item {
    padding-top: 10px !important;
    font-size: 1.1em !important;
    font-family: sans-serif !important;
}

.like-btn {
    background-color: rgba(45, 45, 115, 0.46) !important;
    border-color: rgba(45, 45, 115, 0.46) !important;
    padding: 0.25rem 0.98rem !important;
    border-radius: 5px !important;
    line-height: 1.4 !important;
    transition: 0.3s ease !important;
}

.like-btn:hover {
    transform: translateY(-2px) !important;
    background-color: rgba(45, 45, 115, 0.65) !important;
    border-color: rgba(45, 45, 115, 0.65) !important;
}

.animate-like {
    animation-name: likeAnimation;
    animation-fill-mode: forwards;
    animation-duration: 0.85s;
}

@keyframes likeAnimation {
    0% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}

.fork-btn {
    font-size: 1.1em !important;
    padding-top: 10px !important;
}

.fork-btn-inner {
    line-height: 1.4em !important;
    background-color: rgba(45, 45, 115, 0.46) !important;
    padding: 0.25rem 1.1rem !important;
    vertical-align: middle !important;
    text-align: center !important;
}

.fork-btn-inner:hover {
    transform: translateY(-2px) !important;
    background-color: rgba(45, 45, 115, 0.65) !important;
    border-color: rgba(45, 45, 115, 0.65) !important;
}

.fork-btn-inner::after {
    display: none !important;
}
